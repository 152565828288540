<template>
  <DataTable :options="options" />
</template>

<script>
import { ACCOUNT_TYPES, USER_TYPES, ACCOUNT_PLANS } from '@tutti/constants';

export default {
  data() {
    return {
      account: null,
      options: {
        collection: 'account',
        export: true,
        action: {
          child: 'location',
          showChildList: true,
          showPreview: true,
          onPreview: item => {
            window.open(this.$getPreviewURL(`/${item.slug}`), '_blank');
          },
        },
        actions: [
          {
            text: 'Verify',
            body: { isVerified: true },
          },
          {
            text: 'Unverify',
            body: { isVerified: false },
          },
        ],
        filters: [],
        headers: [
          {
            text: 'Name',
            value: 'name',
            filter: true,
            capitalize: true,
            component: { name: 'dtView', props: { name: 'account', permission: 'account' } },
          },
          { text: 'Type', value: 'type', filter: false, capitalize: true },
          { text: 'User type', value: 'userType', filter: false, capitalize: true },
          {
            text: 'Owner',
            value: 'ownerId',
            filter: true,
            component: { name: 'dtView', props: { name: 'user', permission: 'user' } },
          },
          {
            text: 'Guest',
            value: 'isGuest',
            width: '100px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { disabled: true } },
          },
          {
            text: 'Enabled',
            value: 'isActive',
            width: '50px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Verified',
            value: 'isVerified',
            width: '150px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
          {
            text: 'Sitemap',
            value: 'sitemap',
            width: '120px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
        ],
      },
    };
  },
  created() {
    this.options.filters = [
      {
        label: 'Profile types',
        items: ACCOUNT_TYPES,
        key: 'types',
      },
      {
        label: 'User types',
        items: USER_TYPES,
        key: 'userTypes',
      },
      {
        label: 'Plan',
        items: ACCOUNT_PLANS,
        key: 'plan',
      },
      {
        label: 'TBD',
        items: [],
        itemText: 'text',
        itemValue: 'value',
        key: 'tbd2',
        disabled: true,
      },
    ];
  },
};
</script>
